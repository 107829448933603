import WhatsApp from "../asset/whatsapp.png";
import Instagram from "../asset/instagram.png";
import Facebook from "../asset/facebook.png";
import { Box, Link, Typography } from "@mui/material";
import join from "../asset/join.jpg";

function ContactUs() {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="space-around"
      pb={15}
    >
      <Box>
        <img src={join} alt="join-img" height="350px" />
      </Box>
      <Box pt={2}>
        <Typography variant="h4" align="center">
          Contact Us
        </Typography>
        <Typography align="center">
          You can mail us on query@newskhabree.com
        </Typography>
        <Typography variant="h4" align="center">
          Follow us
        </Typography>
        <Box pt={5}  align="center">
          <Link
            href="https://chat.whatsapp.com/FguBB4M3Y78EtKMDeIybX4"
            target="blank"
            sx={{ margin: "10px" }}
          >
            <img src={WhatsApp} alt="whatsapp" height={50} width={50} />
          </Link>
          <Link
            href="https://www.instagram.com/newskhabree"
            target="blank"
            sx={{ margin: "10px" }}
          >
            <img src={Instagram} alt="instagram" height={50} width={50} />
          </Link>
          <Link
            href="https://www.facebook.com/groups/920638276295821/"
            target="blank"
            sx={{ margin: "10px" }}
          >
            <img src={Facebook} alt="facebook" height={50} width={50} />
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default ContactUs;

import { Box, Typography } from "@mui/material";
import React from "react";

function AboutUs() {
  return (
    <Box mt={5} pl={5} pr={5} pb={2}>
      <Typography align="justify" variant="h3" color="secondary" mb={2}>
        About us
      </Typography>
      <Typography align="justify" >
        <span style={{ fontWeight: "bold", fontSize: "25px", color: "yellow" }}>
          newskhabree.com
        </span>{" "}
        <span style={{ fontWeight: "normal", fontSize: "20px", }}>
          एक न्‍यूज ब्‍लॉग वेबसाइट है जिसमे हम वर्तमान मे प्रचलित और खास खबरों
          के बारे में ब्‍लॉग लिखते है इसमे हम कई सारी केटेगरी के अनुसार ब्‍लाॅग
          आर्टिकल का प्रदर्शन किया जाएगा। इस वेबसाइट पर हम शिक्षा, मनोरंजन,
          टेकनोलाॅजी, और इन सभी सेक्‍टर के अंतर्गत आने वाली नई विज्ञप्तियां और
          साथ में सभी प्रकार की खबरों का प्रदर्शन आर्टिकलाें के माध्‍यम से किया
          जाएगा। सभी प्रकार की नई प्रस्‍तुतियाेंं में हम नई टेकनोलाॅजी, जैसे कि
          नए स्‍मार्टफोन, गाडियां, मूवीज और भी इन सभी खबरो का आर्टिकल पोस्‍ट
          करेंगें।
        </span>
        <span
          style={{
            fontWeight: "bold",
            fontSize: "25px",
            color: "yellow",
            display: "block",
          }}
        >
          newskhabree.com{" "}
          <span
            style={{ fontWeight: "normal", fontSize: "20px", color: "black",}}
          >
            वेबसाइट का उद्देश्‍य सभी प्रकार की न्‍यूज से वेबसाइट पर आने वाले
            पाठकों को प्रचलित न्‍यूजों से अवगत कराना है और इन खबरों के माध्‍यम
            से पाठक भी अपना ज्ञान संर्वधन करना है जिससे पाठक वर्तमान में प्रचलित
            खबरो से अवगत रहे और इसके साथ ही वह अपना नैतिक और व्‍यवहारिक ज्ञान को
            बढा सके। हमारे द्वारा प्रस्‍तुत आर्टिकलो निम्‍न से संबंधित रहेंगें।
          </span>
        </span>{" "}
      </Typography>
      <Typography align="justify" mt={2}>
        <ul style={{ fontWeight: "normal", fontSize: "20px", }}>
          <li>शिक्षा</li>
          <li> खेलकूंद</li>
          <li>मनोरंजन</li>
          <li>टेकनोलॉजी</li>
          <li>अन्‍य</li>
        </ul>
      </Typography>
    </Box>
  );
}

export default AboutUs;

import { useEffect, useState } from "react";
import {
  Box,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { sortedMasterData } from "./MasterData";

const DetaiedCard = () => {
  const { contentType } = useParams();
  const [cardContent, setCardContent] = useState(null);
  useEffect(() => {
    const filteredData = sortedMasterData?.filter(
      (info) =>
        info.job_details.replace(/\s+/g, "-").toLowerCase() ===
        String(contentType)
    );
    setCardContent(filteredData.length > 0 ? filteredData[0] : null);
  }, [contentType]);

  return (
    <>
      {
        <Box key={cardContent?.id} mb={5}>
          <Box p={5}>
            <Typography
              id="content-heading"
              variant="h2"
              align="center"
              p={1}
              color="#CC184E"
              sx={{ fontFamily: "Philosopher" }}
            >
              {cardContent?.job_heading}
            </Typography>
            <Typography
              id="content-description"
              variant="h3"
              align="center"
              p={1}
              color="#5983FC"
              sx={{ fontFamily: "Philosopher" }}
            >
              {cardContent?.job_details}
            </Typography>
            <Box p={2}>
              <Typography
                id="content-description"
                variant="h4"
                p={1}
                color="#7827EC"
                sx={{ fontFamily: "Philosopher" }}
              >
                Important Dates and Fees
              </Typography>
              <Box
                sx={{
                  boxShadow: "rgba(3, 102, 214, 0.3) 0px 0px 0px 3px",
                  borderRadius: "10px",
                }}
              >
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: "25px",
                            fontFamily: "Philosopher",
                            color: "#5983FC",
                          }}
                        >
                          Application Start From
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "25px",
                            fontFamily: "Philosopher",
                            color: "#CC184E",
                          }}
                        >
                          {cardContent?.date_and_misc_data?.start_date}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: "25px",
                            fontFamily: "Philosopher",
                            color: "#5983FC",
                          }}
                        >
                          Last Date
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "25px",
                            fontFamily: "Philosopher",
                            color: "#CC184E",
                          }}
                        >
                          {cardContent?.date_and_misc_data?.last_date}
                        </TableCell>
                      </TableRow>
                      {cardContent?.date_and_misc_data?.correction_date && (
                        <TableRow>
                          <TableCell
                            sx={{
                              fontSize: "25px",
                              fontFamily: "Philosopher",
                              color: "#5983FC",
                            }}
                          >
                            Correction Date
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: "25px",
                              fontFamily: "Philosopher",
                              color: "#CC184E",
                            }}
                          >
                            {cardContent?.date_and_misc_data?.correction_date}
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: "25px",
                            fontFamily: "Philosopher",
                            color: "#5983FC",
                          }}
                        >
                          Fees
                        </TableCell>
                        {cardContent?.date_and_misc_data?.fees?.all_category ? (
                          <TableCell>
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontSize: "20px",
                                  border: "none",
                                  fontFamily: "Philosopher",
                                  color: "#CC184E",
                                }}
                              >
                                All Category
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "20px",
                                  border: "none",
                                  fontFamily: "Philosopher",
                                  color: "#CC184E",
                                }}
                              >
                                {
                                  cardContent?.date_and_misc_data?.fees
                                    ?.all_category
                                }
                              </TableCell>
                            </TableRow>
                          </TableCell>
                        ) : (
                          <TableCell>
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontSize: "20px",
                                  border: "none",
                                  fontFamily: "Philosopher",
                                  color: "#CC184E",
                                }}
                              >
                                General
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "20px",
                                  border: "none",
                                  fontFamily: "Philosopher",
                                  color: "#CC184E",
                                }}
                              >
                                {cardContent?.date_and_misc_data?.fees?.general}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontSize: "20px",
                                  border: "none",
                                  fontFamily: "Philosopher",
                                  color: "#CC184E",
                                }}
                              >
                                OBC
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "20px",
                                  border: "none",
                                  fontFamily: "Philosopher",
                                  color: "#CC184E",
                                }}
                              >
                                {cardContent?.date_and_misc_data?.fees?.obc}
                              </TableCell>{" "}
                            </TableRow>
                            {cardContent?.date_and_misc_data?.fees?.ews && (
                              <TableRow>
                                <TableCell
                                  sx={{
                                    fontSize: "20px",
                                    border: "none",
                                    fontFamily: "Philosopher",
                                    color: "#CC184E",
                                  }}
                                >
                                  EWS
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontSize: "20px",
                                    border: "none",
                                    fontFamily: "Philosopher",
                                    color: "#CC184E",
                                  }}
                                >
                                  {cardContent?.date_and_misc_data?.fees?.ews}
                                </TableCell>{" "}
                              </TableRow>
                            )}
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontSize: "20px",
                                  border: "none",
                                  fontFamily: "Philosopher",
                                  color: "#CC184E",
                                }}
                              >
                                SC/ST
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "20px",
                                  border: "none",
                                  fontFamily: "Philosopher",
                                  color: "#CC184E",
                                }}
                              >
                                {cardContent?.date_and_misc_data?.fees?.sc}
                              </TableCell>{" "}
                            </TableRow>
                            {cardContent?.date_and_misc_data?.fees?.female && (
                              <TableRow>
                                <TableCell
                                  sx={{
                                    fontSize: "20px",
                                    border: "none",
                                    fontFamily: "Philosopher",
                                    color: "#CC184E",
                                  }}
                                >
                                  All Category Female
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontSize: "20px",
                                    border: "none",
                                    fontFamily: "Philosopher",
                                    color: "#CC184E",
                                  }}
                                >
                                  {
                                    cardContent?.date_and_misc_data?.fees
                                      ?.female
                                  }
                                </TableCell>{" "}
                              </TableRow>
                            )}
                            {cardContent?.date_and_misc_data?.fees?.ph && (
                              <TableRow>
                                <TableCell
                                  sx={{
                                    fontSize: "20px",
                                    border: "none",
                                    fontFamily: "Philosopher",
                                    color: "#CC184E",
                                  }}
                                >
                                  PH
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontSize: "20px",
                                    border: "none",
                                    fontFamily: "Philosopher",
                                    color: "#CC184E",
                                  }}
                                >
                                  {cardContent?.date_and_misc_data?.fees.ph}
                                </TableCell>{" "}
                              </TableRow>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                      <TableCell
                        sx={{
                          fontSize: "25px",
                          fontFamily: "Philosopher",
                          color: "#5983FC",
                        }}
                      >
                        Age
                      </TableCell>
                      <TableCell>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontSize: "20px",
                              border: "none",
                              fontFamily: "Philosopher",
                              color: "#CC184E",
                            }}
                          >
                            Minimum Age
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: "20px",
                              border: "none",
                              fontFamily: "Philosopher",
                              color: "#CC184E",
                            }}
                          >
                            {cardContent?.date_and_misc_data?.age.min_age} Year
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            fontSize: "25px",
                            fontFamily: "Philosopher",
                            color: "#CC184E",
                          }}
                        >
                          <TableCell
                            sx={{
                              fontSize: "20px",
                              border: "none",
                              fontFamily: "Philosopher",
                              color: "#CC184E",
                            }}
                          >
                            Maximum Age
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: "20px",
                              border: "none",
                              fontFamily: "Philosopher",
                              color: "#CC184E",
                            }}
                          >
                            {cardContent?.date_and_misc_data?.age.max_age} Year
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontSize: "20px",
                              border: "none",
                              fontFamily: "Philosopher",
                              color: "#CC184E",
                            }}
                            colSpan={2}
                          >
                            Age Relaxation are same as mentioned in
                            notification.
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: "25px",
                            fontFamily: "Philosopher",
                            color: "#5983FC",
                          }}
                        >
                          Exam Date
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "25px",
                            fontFamily: "Philosopher",
                            color: "#CC184E",
                          }}
                        >
                          {cardContent?.date_and_misc_data?.exam_date
                            ? cardContent?.date_and_misc_data?.exam_date
                            : "will update soon"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: "25px",
                            fontFamily: "Philosopher",
                            color: "#5983FC",
                          }}
                        >
                          Apply Online
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "25px",
                            fontFamily: "Philosopher",
                            color: "#CC184E",
                          }}
                        >
                          <Link
                            href={cardContent?.date_and_misc_data?.apply_link}
                            target="blank"
                            underline="hover"
                          >
                            Click Here
                          </Link>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: "25px",
                            fontFamily: "Philosopher",
                            color: "#5983FC",
                          }}
                        >
                          Official Notification
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "25px",
                            fontFamily: "Philosopher",
                            color: "#CC184E",
                          }}
                        >
                          <Link
                            href={
                              cardContent?.date_and_misc_data
                                ?.official_notification_link
                            }
                            target="blank"
                            underline="hover"
                          >
                            Click Here
                          </Link>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: "25px",
                            fontFamily: "Philosopher",
                            color: "#5983FC",
                          }}
                        >
                          Official Website
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "25px",
                            fontFamily: "Philosopher",
                            color: "#CC184E",
                          }}
                        >
                          <Link
                            href={
                              cardContent?.date_and_misc_data?.official_web_link
                            }
                            target="blank"
                            underline="hover"
                          >
                            Click Here
                          </Link>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            {cardContent?.post_details.length !== 0 && (
              <Box p={2}>
                <Typography
                  variant="h4"
                  sx={{ fontFamily: "Philosopher", color: "#7827EC" }}
                  p={1}
                >
                  Post Details
                </Typography>
                <Box
                  sx={{
                    boxShadow: "rgba(3, 102, 214, 0.3) 0px 0px 0px 3px",
                    borderRadius: "10px",
                  }}
                >
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontSize: "25px",
                              fontFamily: "Philosopher",
                              color: "#CC184E",
                            }}
                          >
                            Post
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: "25px",
                              fontFamily: "Philosopher",
                              color: "#CC184E",
                            }}
                          >
                            Number of posts
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: "25px",
                              fontFamily: "Philosopher",
                              color: "#CC184E",
                            }}
                          >
                            Eligibility / Minimum Qualification
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cardContent?.post_details?.map((postsData, index) => (
                          <TableRow key={index}>
                            <TableCell
                              sx={{
                                fontSize: "25px",
                                fontFamily: "Philosopher",
                                color: "#5983FC",
                              }}
                            >
                              {postsData?.post_name}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "25px",
                                fontFamily: "Philosopher",
                                color: "#CC184E",
                              }}
                            >
                              {postsData?.no_of_post}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "25px",
                                fontFamily: "Philosopher",
                                color: "#CC184E",
                              }}
                            >
                              {postsData?.eligibility?.map((eldata, index) => (
                                <ul key={index}>
                                  <li>{eldata}</li>
                                </ul>
                              ))}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                {cardContent?.physical_data && (
                  <Typography
                    pt={2}
                    sx={{
                      fontSize: "25px",
                      fontFamily: "Philosopher",
                      color: "#5983FC",
                    }}
                  >
                    Physical Information :{" "}
                    <Link
                      href={
                        cardContent?.date_and_misc_data
                          ?.official_notification_link
                      }
                      target="blank"
                      underline="hover"
                    >
                      {cardContent?.physical_data}
                    </Link>
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Box>
      }
    </>
  );
};

export default DetaiedCard;

import { useState } from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo from "./asset/News-khabree-logo.png";
import { NavLink } from "react-router-dom";
import {
  Home,
  Work,
  School,
  Engineering,
  CrisisAlertRounded,
  Article,
  BookOnline,
  AdsClickRounded,
} from "@mui/icons-material";

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        position: "sticky",
        top: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        background: "#000",
        zIndex: "100",
      }}
    >
      <Box sx={{ paddingBottom: "8px", paddingTop: "8px" }} width="10%">
        <NavLink to="/">
          <img src={logo} alt="news-khabree-logo" height={50} />
        </NavLink>
      </Box>
      <IconButton
        sx={{
          display: { md: "none" }, // Hide on medium and larger screens
          color: "lime",
          marginLeft: "auto",
        }}
        onClick={handleMobileMenuOpen}
      >
        <MenuIcon />
      </IconButton>
      <Box
        display={{ xs: "none", md: "flex" }} // Hide on extra-small screens
        justifyContent="center"
        flexWrap="wrap"
        width="90%"
      >
        <NavLink
          to="/"
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            marginLeft: "16px",
            fontFamily: "Philosopher",
            fontSize: "20px",
            color: "lime",
          }}
        >
          Home
        </NavLink>
        <NavLink
          to="/"
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            marginLeft: "16px",
            fontFamily: "Philosopher",
            fontSize: "20px",
            color: "lime",
          }}
        >
          All Jobs
        </NavLink>
        <NavLink
          to="/mpgovtjobs"
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            marginLeft: "16px",
            fontFamily: "Philosopher",
            fontSize: "20px",
            color: "lime",
          }}
        >
          MP Govt. Jobs
        </NavLink>
        <NavLink
          to="/pass/10"
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            marginLeft: "16px",
            fontFamily: "Philosopher",
            fontSize: "20px",
            color: "lime",
          }}
        >
          10th Pass Jobs
        </NavLink>
        <NavLink
          to="/pass/12"
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            marginLeft: "16px",
            fontFamily: "Philosopher",
            fontSize: "20px",
            color: "lime",
          }}
        >
          12th Pass Jobs
        </NavLink>
        <NavLink
          to="/pass/egdiploma"
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            marginLeft: "16px",
            fontFamily: "Philosopher",
            fontSize: "20px",
            color: "lime",
          }}
        >
          Engineering Jobs
        </NavLink>
        <NavLink
          to="/admission"
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            marginLeft: "16px",
            fontFamily: "Philosopher",
            fontSize: "20px",
            color: "lime",
          }}
        >
          Admission
        </NavLink>
      </Box>
      <Drawer
        anchor="right"
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <Box sx={{ width: 250 }}>
          <IconButton
            sx={{ marginLeft: "auto" }}
            onClick={handleMobileMenuClose}
          >
            <CloseIcon />
          </IconButton>
          <Box display="flex" flexDirection="column" alignItems="center" p={2}>
            <List>
              <ListItem disablePadding onClick={() => setMobileMenuOpen(false)}>
                <ListItemIcon>
                  <Home color="primary" />
                </ListItemIcon>
                <ListItemText>
                  <NavLink
                    to="/"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      marginLeft: "16px",
                      fontFamily: "Philosopher",
                      fontSize: "20px",
                      color: "lime",
                    }}
                  >
                    Home
                  </NavLink>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding onClick={() => setMobileMenuOpen(false)}>
                <ListItemIcon>
                  <CrisisAlertRounded color="primary" />
                </ListItemIcon>
                <ListItemText>
                  <NavLink
                    to="/mpgovtjobs"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      marginLeft: "16px",
                      fontFamily: "Philosopher",
                      fontSize: "20px",
                      color: "lime",
                    }}
                  >
                    MP Govt. Jobs
                  </NavLink>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding onClick={() => setMobileMenuOpen(false)}>
                <ListItemIcon>
                  <AdsClickRounded color="primary" />
                </ListItemIcon>
                <ListItemText>
                  <NavLink
                    to="/pass/10"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      marginLeft: "16px",
                      fontFamily: "Philosopher",
                      fontSize: "20px",
                      color: "lime",
                    }}
                  >
                    10th Pass Jobs
                  </NavLink>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding onClick={() => setMobileMenuOpen(false)}>
                <ListItemIcon>
                  <AdsClickRounded color="primary" />
                </ListItemIcon>
                <ListItemText>
                  <NavLink
                    to="/pass/12"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      marginLeft: "16px",
                      fontFamily: "Philosopher",
                      fontSize: "20px",
                      color: "lime",
                    }}
                  >
                    12th Pass Jobs
                  </NavLink>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding onClick={() => setMobileMenuOpen(false)}>
                <ListItemIcon>
                  <Engineering color="primary" />
                </ListItemIcon>
                <ListItemText>
                  <NavLink
                    to="/pass/egdiploma"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      marginLeft: "16px",
                      fontFamily: "Philosopher",
                      fontSize: "20px",
                      color: "lime",
                    }}
                  >
                    Engineering Jobs
                  </NavLink>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding onClick={() => setMobileMenuOpen(false)}>
                <ListItemIcon>
                  <School color="primary" />
                </ListItemIcon>
                <ListItemText>
                  <NavLink
                    to="/admission"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      marginLeft: "16px",
                      fontFamily: "Philosopher",
                      fontSize: "20px",
                      color: "lime",
                    }}
                  >
                    Admission
                  </NavLink>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding onClick={() => setMobileMenuOpen(false)}>
                <ListItemIcon>
                  <Work color="primary" />
                </ListItemIcon>
                <ListItemText>
                  <NavLink
                    to="/university"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      marginLeft: "16px",
                      fontFamily: "Philosopher",
                      fontSize: "20px",
                      color: "lime",
                    }}
                  >
                    University Form
                  </NavLink>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding onClick={() => setMobileMenuOpen(false)}>
                <ListItemIcon>
                  <Article color="primary" />
                </ListItemIcon>
                <ListItemText>
                  <NavLink
                    to="/news"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      marginLeft: "16px",
                      fontFamily: "Philosopher",
                      fontSize: "20px",
                      color: "lime",
                    }}
                  >
                    News
                  </NavLink>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding onClick={() => setMobileMenuOpen(false)}>
                <ListItemIcon>
                  <BookOnline color="primary" />
                </ListItemIcon>
                <ListItemText>
                  <NavLink
                    to="/services"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      marginLeft: "16px",
                      fontFamily: "Philosopher",
                      fontSize: "20px",
                      color: "lime",
                    }}
                  >
                    Services
                  </NavLink>
                </ListItemText>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Header;

import React from "react";
// import ReactDOM from "react-dom/client";
import App from "./App";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import AllJobs from "./AllJobs";
import AboutUs from "./Mandatory Pages/AboutUs";
import Discliamer from "./Mandatory Pages/Discliamer";
import PrivacyPolicy from "./Mandatory Pages/PrivacyPolicy";
import ContactUs from "./Mandatory Pages/ContactUs";
import ErrorPage from "./ErrorPage";
import DetaiedCard from "./DetaiedCard";
import NavPage from "./NavPage";
import PrivateJobs from "./PrivateJobs";
import { hydrate } from "react-dom";
import { render } from "react-dom";
import QualificationFilter from "./QualificationFilter";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="" element={<AllJobs />} errorElement={<ErrorPage />} />
      <Route
        path="/about_us"
        element={<AboutUs />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/disclamer"
        element={<Discliamer />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/privacy_policy"
        element={<PrivacyPolicy />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/contact_us"
        element={<ContactUs />}
        errorElement={<ErrorPage />}
      />

      <Route
        path="/jobs/:contentType"
        element={<DetaiedCard />}
        errorElement={<ErrorPage />}
      />
      <Route path="/:type" element={<NavPage />} errorElement={<ErrorPage />} />
      <Route
        path="/university"
        element={<PrivateJobs />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/pass/:classholder"
        element={<QualificationFilter />}
        errorElement={<ErrorPage />}
      />
    </Route>
  )
);

const webApp = (
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

const root = document.getElementById("root");
if (root.hasChildNodes()) {
  hydrate(webApp, root);
} else {
  render(webApp, root);
}

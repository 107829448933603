import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { sortedMasterData } from "./MasterData";
import GJob from "./asset/Govt. Jobs.png";
import universityIcon from "./asset/university.png";
import privateJob from "./asset/private.jpg";
import news from "./asset/news.jpg";
import mp from "./asset/mp.png";
import admission from "./asset/admission.png";

function NavPage() {
  const [filteredContent, setFilteredContent] = useState([]);
  const category = useParams();

  useEffect(() => {
    const filteredData = sortedMasterData?.filter(
      (data) => data?.category === category?.type
    );
    if (filteredData.length) {
      setFilteredContent(filteredData);
    } else {
      setFilteredContent([]);
    }
  }, [category]);
  return (
    <Box display="flex" justifyContent="start" flexDirection="column" pb={10}>
      <Button variant="contained" size="large" color="success">
        {category?.type === "admission" ? "Admission" : "Latest Jobs"}
      </Button>
      {filteredContent?.map((data) => (
        <Link
          to={`/jobs/${data.job_details.replace(/\s+/g, "-").toLowerCase()}`}
          style={{ textDecoration: "none" }}
        >
          <Box sx={{ width: "100%", bgcolor: "background.paper" }} id={data.id}>
            <Typography aria-label="main mailbox folders">
              <List>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon sx={{ marginRight: "8px" }}>
                      <img
                        src={
                          data.category === "govtjobs"
                            ? GJob
                            : data.category === "privatejobs"
                            ? privateJob
                            : data.category === "news"
                            ? news
                            : data.category === "mpgovtjobs"
                            ? mp
                            : data.category === "admission"
                            ? admission
                            : universityIcon
                        }
                        alt={data.category}
                        height={50}
                        width={100}
                      />
                    </ListItemIcon>
                    <ListItemText primary={data.job_details} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Typography>
            <Divider />
          </Box>
        </Link>
      ))}
    </Box>
  );
}

export default NavPage;

// const category = ["govtjobs", "university", "mpgovtjobs", "admission"];
const masterData = [
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: true,
      12: true,
      graduate: true,
      egdiploma: true,
    },
    job_heading: "Staff Selection Commision (SSC) OTR Registration 2024",
    job_details:
      "Staff Selection Commision (SSC) OTR Registration 2024",
    date_and_misc_data: {
      start_date: " 22/02/2024",
      last_date: "No Last Date",
      correction_date: "",
      fees: {
        general: "",
        obc: "",
        sc: "",
        ews: "",
        female: "",
        ph: "",
        all_category: "0/-",
      },
      age: { min_age: "18", max_age: "NA" },
      exam_date: "",
      apply_link: "https://ssc.gov.in/candidate-portal/one-time-registration/home-page",
      official_notification_link:
        "https://ssc.nic.in/SSCFileServer/PortalManagement/UploadedFiles/OTR_Notice_22022024.pdf",
      official_web_link: "https://ssc.gov.in/",
    },
    post_details: [
      {
        post_name: "All SSC Related Posts",
        no_of_post: "",
        eligibility: [
          "According To vacancies",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "UP Police Recruitment 2023",
    job_details:
      "UP Police SI Confidential,  ASI Clerk & Accountant Recruitment 2023",
    date_and_misc_data: {
      start_date: " 07/01/2024",
      last_date: " 31/01/2024",
      correction_date: " 01/02/2024",
      fees: {
        general: "400/-",
        obc: "400/-",
        sc: "400/-",
        ews: "400/-",
        female: "400/-",
        ph: "400/-",
        all_category: "400/-",
      },
      age: { min_age: "21", max_age: "28" },
      exam_date: "",
      apply_link: "https://si.onlinereg.co.in/",
      official_notification_link:
        "https://uppbpb.gov.in/FilesUploaded/Notice/vig1_28.12.2023ca61b9b8-bb72-49a3-899d-491f0fdc6ac5e000248c-f3b2-40c8-bc3a-162704281b86.pdf",
      official_web_link: "https://uppbpb.gov.in/",
    },
    post_details: [
      {
        post_name: "Sub Inspector of Police (Confidential)",
        no_of_post: "268",
        eligibility: [
          "Bachelor Degree in Any Stream in Any Recognized University.",
          "Hindi Typing 25 WPM & English Typing 30 WPM",
          "Stenographer Hindi : 80 WPM",
          "O Level Exam Passed.",
        ],
      },
      {
        post_name: "Assistant Sub Inspector of Police (Ministerial)",
        no_of_post: "449",
        eligibility: [
          "Bachelor Degree in Any Stream in Any Recognized University.",
          "Hindi Typing 25 WPM & English Typing 30 WPM",
          "O Level Exam Passed.",
        ],
      },
      {
        post_name: "Assistant Sub Inspector of Police (Accounts)",
        no_of_post: "204",
        eligibility: [
          "Bachelor Degree in Commerce from Any Recognized University.",
          "Hindi Typing 25 WPM & English Typing 15 WPM",
          "O Level Exam Passed.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: true,
    },
    job_heading: "Railway Recruitment 2024",
    job_details: "Railway Recruitment Board RRB Assistant Loco Pilot ALP",
    date_and_misc_data: {
      start_date: " 20/01/2024",
      last_date: " 19/02/2024",
      correction_date: " 20 to 29 Feb 2024",
      fees: {
        general: "500/-",
        obc: "250/-",
        sc: "250/-",
        ews: "250/-",
        female: "250/-",
        ph: "",
        all_category: "",
      },
      age: { min_age: "18", max_age: "30" },
      exam_date: "",
      apply_link: "https://www.recruitmentrrb.in/#/auth/home?flag=true",
      official_notification_link:
        "https://467362959265-rrbdocuments.s3.ap-south-1.amazonaws.com/dev/advt/Detailed%2BCEN%2B01-2024.pdf?X-Amz-Expires=604800&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWZUIAZ6Q4GSTC76M%2F20240120%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240120T165826Z&X-Amz-SignedHeaders=host&X-Amz-Signature=af0cefee1cad839eee3fdf39fc306bd8de91ab0ff8e2a866923a046f98b8290b",
      official_web_link: "https://www.recruitmentrrb.in/#/auth/landing",
    },
    post_details: [
      {
        post_name: "Assistant Loco Pilot",
        no_of_post: "5696",
        eligibility: [
          "Class 10th Matric with ITI from NCVT / SCVT Certificate in Fitter, Electrician, Instrument Mechanic, Millwright, Maintenance Mechanic, Mechanic Radio and TV, Electronics Mechanic, Mechanic Motor Vehicle, Wireman, Tractor Mechanic, Armature and Coil Winder, mechanic Diesel, Heat Engine, Turner, Machinist, Refrigeration and AC OR",
          "Class 10th with Diploma in Mechanical / Electrical / Electronics / Automobile Engineering OR",
          "BE / B.Tech Degree in Mechanical / Electrical / Electronics / Automobile Engineering",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "Rajasthan High Court Recruitment 2024",
    job_details:
      "Rajasthan High Court RHC Junior Personal Assistant Hindi Recruitment 2024",
    date_and_misc_data: {
      start_date: "09/02/2024",
      last_date: " 09/03/2024",
      correction_date: "",
      fees: {
        general: "750/-",
        obc: "600/-",
        sc: "450/-",
        ews: "450/-",
        female: "",
        ph: "450/-",
        all_category: "",
      },
      age: { min_age: "18", max_age: "40" },
      exam_date: "",
      apply_link: "https://hcraj.nic.in/hcraj/recruitment_detail.php?id=NDQ=",
      official_notification_link:
        "https://hcraj.nic.in/hcraj/hcraj_admin/uploadfile/recruitment/JPAH170556741512.pdf",
      official_web_link: "https://hcraj.nic.in/hcraj/",
    },
    post_details: [
      {
        post_name: "Junior Personal Assistant Hindi",
        no_of_post: "30",
        eligibility: [
          "Bachelor Degree in Any Stream from Any Recognized University in India.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: true,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "Rajasthan Staff Selection Board Recruitment 2024",
    job_details: "Rajasthan RSMSSB Animal Attendant Recruitment 2024",
    date_and_misc_data: {
      start_date: "19/01/2024",
      last_date: " 17/02/2024",
      correction_date: "",
      fees: {
        general: "600/-",
        obc: "400/-",
        sc: "400/-",
        ews: "450/-",
        female: "",
        ph: "450/-",
        all_category: "",
      },
      age: { min_age: "18", max_age: "40" },
      exam_date: "",
      apply_link: "https://sso.rajasthan.gov.in/signin",
      official_notification_link:
        "https://rsmssb.rajasthan.gov.in/Static/files/Animal%20Attandant_2023.pdf",
      official_web_link: "https://rsmssb.rajasthan.gov.in/page?menuName=Home",
    },
    post_details: [
      {
        post_name: "Animal Attendant Non TSP",
        no_of_post: "5281",
        eligibility: [
          "Class 10th Matric in Any Recognized Board in India.",
          "Knowledge of Devngari Lipi & Rajasthani Culture.",
          "More Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Animal Attendant TSP",
        no_of_post: "653",
        eligibility: [
          "Class 10th Matric in Any Recognized Board in India.",
          "Knowledge of Devngari Lipi & Rajasthani Culture.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "Delhi DSSSB Recruitment 2024",
    job_details: "Delhi DSSSB Trained Graduate Teacher TGT Recruitment 2024",
    date_and_misc_data: {
      start_date: "08/02/2024",
      last_date: " 08/03/2024",
      correction_date: "",
      fees: {
        general: "100/-",
        obc: "100/-",
        sc: "0/-",
        ews: "100/-",
        female: "0",
        ph: "0/-",
        all_category: "",
      },
      age: { min_age: "18", max_age: "32" },
      exam_date: "",
      apply_link: "https://dsssbonline.nic.in/",
      official_notification_link:
        "https://dsssb.delhi.gov.in/sites/default/files/DSSSB/circulars-orders/short_advt._02-2024.pdf",
      official_web_link: "https://dsssb.delhi.gov.in/",
    },
    post_details: [
      {
        post_name: "TGT & Drawing Teacher",
        no_of_post: "5118",
        eligibility: [
          "Bachelor Degree in Related Subject.",
          "Degree / Diploma in Education.",
          "CTET Exam Passed.",
          "For Subject Wise Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "Delhi DSSSB Recruitment 2024",
    job_details: "DSSSB Section Officer (Horticulture)  Recruitment 2023",
    date_and_misc_data: {
      start_date: "09/01/2024",
      last_date: " 07/02/2024",
      correction_date: "",
      fees: {
        general: "100/-",
        obc: "100/-",
        sc: "0/-",
        ews: "100/-",
        female: "0",
        ph: "0/-",
        all_category: "",
      },
      age: { min_age: "18", max_age: "27-32" },
      exam_date: "",
      apply_link: "https://dsssbonline.nic.in/",
      official_notification_link:
        "https://dsssb.delhi.gov.in/sites/default/files/DSSSB/circulars-orders/final_06-23_advt_combined_section_officer_horticulture_1.pdf",
      official_web_link: "https://dsssb.delhi.gov.in/",
    },
    post_details: [
      {
        post_name: "Section Officer Horticulture (MCD)",
        no_of_post: "89",
        eligibility: [
          "Bachelor Degree in Agriculture OR B.Sc with Botany as a Subject",
          "Age Limit : 18-27 Years",
          "For Subject Wise Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Section Officer Horticulture (NDMC)",
        no_of_post: "19",
        eligibility: [
          "B.SC Agriculture (First / Second Class) with 2 Year Experience",
          "Age Limit : 18-32 Years",
          "For Subject Wise Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: true,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "Delhi DSSSB Recruitment 2024",
    job_details: "DSSSB Assistant Teacher Nursery",
    date_and_misc_data: {
      start_date: "09/01/2024",
      last_date: " 07/02/2024",
      correction_date: "",
      fees: {
        general: "100/-",
        obc: "100/-",
        sc: "0/-",
        ews: "100/-",
        female: "0",
        ph: "0/-",
        all_category: "",
      },
      age: { min_age: "NA", max_age: "30" },
      exam_date: "",
      apply_link: "https://dsssbonline.nic.in/",
      official_notification_link:
        "https://dsssb.delhi.gov.in/sites/default/files/DSSSB/circulars-orders/final_08-23_assistant_teacher_nursery_1.pdf",
      official_web_link: "https://dsssb.delhi.gov.in/",
    },
    post_details: [
      {
        post_name: "Assistant Teacher (Nursery)",
        no_of_post: "1455",
        eligibility: [
          "10+2 Intermediate with 45% Marks, Diploma / Certificate in Nursery Teacher Education Programme OR B.Ed Nursery",
          "For Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: true,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "Delhi DSSSB Recruitment 2024",
    job_details: "DSSSB PGT Teacher",
    date_and_misc_data: {
      start_date: "09/01/2024",
      last_date: " 07/02/2024",
      correction_date: "",
      fees: {
        general: "100/-",
        obc: "100/-",
        sc: "0/-",
        ews: "100/-",
        female: "0",
        ph: "0/-",
        all_category: "",
      },
      age: { min_age: "NA", max_age: "36" },
      exam_date: "",
      apply_link: "https://dsssbonline.nic.in/",
      official_notification_link:
        "https://dsssb.delhi.gov.in/sites/default/files/DSSSB/circulars-orders/final_07-23_advt_combined_pgts.pdf",
      official_web_link: "https://dsssb.delhi.gov.in/",
    },
    post_details: [
      {
        post_name: "Delhi DSSB PGT Teacher",
        no_of_post: "297",
        eligibility: [
          "Master Degree in Related Subject.",
          "Degree / Diploma in Education.",
          "For Subject Wise Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: true,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "Delhi DSSSB Recruitment 2024",
    job_details:
      "DSSSB LDC, Junior Assistant, Steno & Other Post Recruitment 2023",
    date_and_misc_data: {
      start_date: "09/01/2024",
      last_date: " 07/02/2024",
      correction_date: "",
      fees: {
        general: "100/-",
        obc: "100/-",
        sc: "0/-",
        ews: "100/-",
        female: "0",
        ph: "0/-",
        all_category: "",
      },
      age: { min_age: "18", max_age: "27-30" },
      exam_date: "",
      apply_link: "https://dsssbonline.nic.in/",
      official_notification_link:
        "https://dsssb.delhi.gov.in/sites/default/files/DSSSB/circulars-orders/final_05-23_advt_combined_1.pdf",
      official_web_link: "https://dsssb.delhi.gov.in/",
    },
    post_details: [
      {
        post_name: "Grade IV Junior Assistant",
        no_of_post: "1672",
        eligibility: [
          "10+2 Intermediate Pass with Computer Typing Speed : 35 WPM English OR 30 WPM Hindi.",
          "Age 18-27 Years",
          "For Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Stenographer (Services Department)",
        no_of_post: "143",
        eligibility: [
          "10+2 Intermediate Pass with Typewriting English Typing 80 WPM or 80 WPM in Shorthand and 35 WPM in Typewriting in Hindi",
          "Age 18-27 Years",
          "For Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Lower Division Clerk LDC Cum Typist Hindi / English",
        no_of_post: "256",
        eligibility: [
          "10+2 Intermediate Pass with Computer Typing Speed : 35 WPM English OR 30 WPM Hindi.",
          "Age 18-27 Years",
          "For Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Junior Stenographer",
        no_of_post: "20",
        eligibility: [
          "10+2 Intermediate with Shorthand Speed 80 WPM and 40 WPM in Typing",
          "Age 18-30 Years",
          "For Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Junior Assistant (SCERT)",
        no_of_post: "40",
        eligibility: [
          "10+2 Intermediate Pass with Computer Typing Speed : 35 WPM English OR 30 WPM Hindi.",
          "Age 18-27 Years",
          "For Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Stenographer (SCERT)",
        no_of_post: "14",
        eligibility: [
          "10+2 Intermediate Pass with Typewriting English Typing 80 WPM or 80 WPM in Shorthand and 35 WPM in Typewriting in Hindi.",
          "Age 18-27 Years",
          "For Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Junior Assistant (Tourism Deptt)",
        no_of_post: "30",
        eligibility: [
          "10+2 Intermediate Pass with Computer Typing Speed : 35 WPM English OR 30 WPM Hindi.",
          "Age 18-27 Years",
          "For Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Junior Stenographer English",
        no_of_post: "02",
        eligibility: [
          "10+2 Intermediate Pass with Shorthand Speed 100 WPM and Typing Speed 40 WPM.",
          "Age 18-27 Years",
          "For Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Junior Assistant (Pollution Control Committee)",
        no_of_post: "28",
        eligibility: [
          "10+2 Intermediate Pass with Computer Typing Speed : 35 WPM English OR 30 WPM Hindi.",
          "Age 18-27 Years",
          "For Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Stenographer Grade II",
        no_of_post: "05",
        eligibility: [
          "10+2 Intermediate with Shorthand Speed 80 WPM and 40 WPM in Typing OR Shorthand Speed 80 WPM and Typewriting Hindi 35 WPM.",
          "Age 18-27 Years",
          "For Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Lower Division Clerk LDC",
        no_of_post: "28",
        eligibility: [
          "10+2 Intermediate Pass with Computer Typing Speed : 35 WPM English OR 30 WPM Hindi.",
          "Age 18-27 Years",
          "For Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Junior Assistant (MAIDS)",
        no_of_post: "10",
        eligibility: [
          "10+2 Intermediate Pass with Computer Typing Speed : 35 WPM English OR 30 WPM Hindi.",
          "Age 18-27 Years",
          "For Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Junior Assistant (MAIDS)",
        no_of_post: "10",
        eligibility: [
          "10+2 Intermediate Pass with Computer Typing Speed : 35 WPM English OR 30 WPM Hindi.",
          "Age 18-27 Years",
          "For Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Assistant Grade I",
        no_of_post: "104",
        eligibility: [
          "10+2 Intermediate with English Typewriting Speed 30 WPM or Hindi 30 WPM on Computer.",
          "Age 18-27 Years",
          "For Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "Delhi DSSSB Recruitment 2024",
    job_details:
      "Delhi DSSSB Sr Personal Assistant, Personal Assistant, Junior Judicial Assistant JJA  Recruitment 2024",
    date_and_misc_data: {
      start_date: "18/01/2024",
      last_date: " 08/02/2024",
      correction_date: "",
      fees: {
        general: "100/-",
        obc: "100/-",
        sc: "0/-",
        ews: "100/-",
        female: "0",
        ph: "0/-",
        all_category: "",
      },
      age: { min_age: "18", max_age: "27" },
      exam_date: "",
      apply_link: "https://dsssbonline.nic.in/",
      official_notification_link:
        "https://dsssb.delhi.gov.in/sites/default/files/DSSSB/circulars-orders/final_advt._no._01-2024_district_court_advt_combined_1.pdf",
      official_web_link: "https://dsssb.delhi.gov.in/",
    },
    post_details: [
      {
        post_name: "Senior Personal Assistant SPA",
        no_of_post: "41",
        eligibility: [
          "Bachelor Degree in Any Stream with Shorthand Speed 110 WPM and Typewriting Speed 40 WPM.",
          "For Subject Wise Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Personal Assistant PA District & Sessions Courts",
        no_of_post: "367",
        eligibility: [
          "Bachelor Degree in Any Stream with Shorthand Speed 110 WPM and Typewriting Speed 40 WPM.",
          "For Subject Wise Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Personal Assistant PA District (Family Court)",
        no_of_post: "16",
        eligibility: [
          "Bachelor Degree in Any Stream with Shorthand Speed 110 WPM and Typewriting Speed 40 WPM.",
          "For Subject Wise Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Junior Judicial Assistant JJA District & Sessions Courts",
        no_of_post: "546",
        eligibility: [
          "Bachelor Degree in Any Stream with Computer Typing Speed 40 WPM.",
          "For Subject Wise Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Junior Judicial Assistant JJA Family Court",
        no_of_post: "20",
        eligibility: [
          "Bachelor Degree in Any Stream with Computer Typing Speed 40 WPM.",
          "For Subject Wise Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: true,
      graduate: true,
      egdiploma: true,
    },
    job_heading: "Airforce Agniveer Vayu Selection Test 2024",
    job_details: "Join Indian Airforce Agnipath Agniveer Scheme 2024",
    date_and_misc_data: {
      start_date: "17/01/2024",
      last_date: " 06/02/2024",
      correction_date: "",
      fees: {
        general: "",
        obc: "",
        sc: "",
        ews: "",
        female: "0",
        ph: "0/-",
        all_category: "550/-",
      },
      age: { min_age: "17.5", max_age: "21" },
      exam_date: "17/03/2024",
      apply_link: "https://agnipathvayu.cdac.in/avreg/candidate/login",
      official_notification_link:
        "https://agnipathvayu.cdac.in/AV/img/upcoming/AGNIVEER_VAYU_01-2025.pdf",
      official_web_link: "https://agnipathvayu.cdac.in/AV/",
    },
    post_details: [
      {
        post_name: "Indian Airforce Agniveer Vayu Intake 01/2025",
        no_of_post: "NA",
        eligibility: [
          "10+2 Intermediate with Mathematics, Physics and English with Minimum 50% Marks. and 50% Marks in English. OR",
          "3 Year Diploma in Engineering (Mechanical/ Electrical/ Electronics/ Automobile/ Computer Science/ Instrumentation Technology/ Information Technology) with Minimum 50% Marks and 50% Marks in English in Diploma Course. OR",
          "2 Year Vocation Course with Non Vocational Subject Physics and Math from Any Recognized Board with 50% Marks Aggregate and 50% Marks in English.",
          "More Details Read the Notification.",
        ],
      },
    ],
    physical_data: "For Physical Details Please Read Notification.",
  },
  {
    id: Date.now(),
    category: "mpgovtjobs",
    minimun_eligibility: {
      10: true,
      12: true,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "SRVS Medical College Shivpuri Recruitment 2024",
    job_details: "SRVS Medical College Shivpuri Non Academic Post of Class IV",
    date_and_misc_data: {
      start_date: "12/01/2024",
      last_date: " 31/01/2024",
      correction_date: "",
      fees: {
        general: "1200/-",
        obc: "600/-",
        sc: "600/-",
        ews: "600/-",
        female: "",
        ph: "600/-",
        all_category: "",
      },
      age: { min_age: "18", max_age: "40" },
      exam_date: "",
      apply_link:
        "https://iforms.mponline.gov.in/Profile/Login?ReturnUrl=%2fForm%2fMain%3fexamId%3d145&examId=145",
      official_notification_link:
        "https://iforms.mponline.gov.in/Form/ViewDocs?rt=RuleBooks/145",
      official_web_link: "https://iforms.mponline.gov.in/",
    },
    post_details: [
      {
        post_name: "Peon",
        no_of_post: "3",
        eligibility: [
          "Only For MP Resident.",
          "8th Pass From Recognized Board",
          "More Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "mpgovtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "MPPSC Recruitment 2024",
    job_details: "Madhya Pradesh MPPSC State Services 2024",
    date_and_misc_data: {
      start_date: "19/01/2024",
      last_date: " 18/02/2024",
      correction_date: "22/02/2024",
      fees: {
        general: "500 + Portel Charges",
        obc: "250 + Portel Charges",
        sc: "250 + Portel Charges",
        ews: "",
        female: "",
        ph: "",
        all_category: "",
      },
      age: {
        min_age: "18",
        max_age: "33 Years for Uniformed Post || 40 Years for Other Post",
      },
      exam_date: "28/04/2024",
      apply_link:
        "https://www.mponline.gov.in/Portal/Examinations/MPPSC/2024/IA23/Declaration.aspx",
      official_notification_link:
        "https://mppsc.mp.gov.in/uploads/advertisement/Advt__State_Service_Examination_2024_Dated_30_12_2023.pdf",
      official_web_link:
        "https://www.mponline.gov.in/Portal/Examinations/MPPSC/Attestation/Home/Home.aspx",
    },
    post_details: [
      {
        post_name: "State Services SSE 2024",
        no_of_post: "60",
        eligibility: [
          "Bachelor Degree in Any Stream in Any Recognized University in India.",
          "More Details Read the Notification.",
        ],
      },
    ],
    physical_data: "Please Read Notification.",
  },
  {
    id: Date.now(),
    category: "mpgovtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: true,
    },
    job_heading: "MPPSC Recruitment 2024",
    job_details: "Madhya Pradesh MPPSC Forest Examination 2024",
    date_and_misc_data: {
      start_date: "19/01/2024",
      last_date: " 18/02/2024",
      correction_date: "22/02/2024",
      fees: {
        general: "500 + Portel Charges",
        obc: "250 + Portel Charges",
        sc: "250 + Portel Charges",
        ews: "250 + Portel Charges",
        female: "",
        ph: "250 + Portel Charges",
        all_category: "",
      },
      age: {
        min_age: "18",
        max_age: "33 Years for Uniformed Post || 40 Years for Other Post",
      },
      exam_date: "28/04/2024",
      apply_link:
        "https://www.mponline.gov.in/Portal/Examinations/MPPSC/2024/IA23/Declaration.aspx",
      official_notification_link:
        "https://mppsc.mp.gov.in/uploads/advertisement/Advt__State_Forest_Service_Examination_2024_Dated_30_12_2023.pdf",
      official_web_link:
        "https://www.mponline.gov.in/Portal/Examinations/MPPSC/Attestation/Home/Home.aspx",
    },
    post_details: [
      {
        post_name: "State Forest SFE 2024",
        no_of_post: "14",
        eligibility: [
          "Bachelor Degree in Science or Engineering or Technical.",
          "More Details Read the Notification.",
        ],
      },
    ],
    physical_data: "Please Read Notification.",
  },
  {
    id: Date.now(),
    category: "mpgovtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: true,
    },
    job_heading: "Madhya Pradesh Metro Rail Recruitment 2024",
    job_details:
      "Madhya Pradesh Metro Rail Various Engineering Post on Deputation",
    date_and_misc_data: {
      start_date: "19/01/2024",
      last_date: " 15/02/2024",
      correction_date: "",
      fees: {
        general: "",
        obc: "",
        sc: "",
        ews: "",
        female: "",
        ph: "",
        all_category: "200 + portal charges",
      },
      age: { min_age: "21", max_age: "40" },
      exam_date: "",
      apply_link:
        "https://iforms.mponline.gov.in/Profile/Login?ReturnUrl=%2fForm%2fMain%3fexamId%3d145&examId=145",
      official_notification_link:
        "https://iforms.mponline.gov.in/Form/ViewDocs?rt=RuleBooks/144",
      official_web_link: "https://iforms.mponline.gov.in/",
    },
    post_details: [
      {
        post_name: "Section Engineer Grade-I / Junior Engineer Grade-II",
        no_of_post: "10",
        eligibility: [
          "3 year engineering diploma or higher in civil engineering with minimum 60% marks/CGPA from govt. recognized university.",
          "Applicant should have experience in construction of viaduct and/or elevated stations for metro rail/ RRTS / High speed rail / elevated railways etc.",
          "More Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "mpgovtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: true,
    },
    job_heading: "Madhya Pradesh Metro Rail Recruitment 2024",
    job_details:
      "Madhya Pradesh Metro Rail Various Engineering Post on Contract",
    date_and_misc_data: {
      start_date: "19/01/2024",
      last_date: " 15/02/2024",
      correction_date: "",
      fees: {
        general: "",
        obc: "",
        sc: "",
        ews: "",
        female: "",
        ph: "",
        all_category: "200 + portal charges",
      },
      age: { min_age: "21", max_age: "40" },
      exam_date: "",
      apply_link:
        "https://iforms.mponline.gov.in/Profile/Login?ReturnUrl=%2fForm%2fMain%3fexamId%3d145&examId=145",
      official_notification_link:
        "https://iforms.mponline.gov.in/Form/ViewDocs?rt=RuleBooks/146",
      official_web_link: "https://iforms.mponline.gov.in/",
    },
    post_details: [
      {
        post_name:
          "Section Engineer Grade-I / Junior Engineer Grade-II (Civil)",
        no_of_post: "14",
        eligibility: [
          "3 year engineering diploma or higher in civil engineering with minimum 60% marks/CGPA from govt. recognized university.",
          "Applicant should have experience in construction of viaduct and/or elevated stations for metro rail/ RRTS / High speed rail / elevated railways etc.",
          "More Details Read the Notification.",
        ],
      },
      {
        post_name:
          "Section Engineer Grade-I / Junior Engineer Grade-II (Auto CAD)",
        no_of_post: "2",
        eligibility: [
          "Diploma engineering in civil. 3 year and auto CAD certification/ Diploma in auto CAD (civil) from govt. recognized university.",
          "Applicant should have experience in preparation of architectureal drowings and reinforcement detailing, favrication, drowings of steel structures, road alignment drowings, bridge detailing etc. using AutoCAD latest version and having relevant Experience with railways or metro project etc. ",
          "More Details Read the Notification.",
        ],
      },
      {
        post_name:
          "Section Engineer Grade-I / Junior Engineer Grade-II (Surveyor)",
        no_of_post: "2",
        eligibility: [
          "Diploma engineering in civil. 3 year or ITI from govt. recognized university.",
          "Applicant should have experience of survey works latest modern equipment's in metro rail, RRTS/High speed rail/elevated railways.",
          "More Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "admission",
    minimun_eligibility: { 10: false, 12: false, graduate: false },
    job_heading: "Shramoday School Examination 2024-25",
    job_details: "Class 6-9 Admission Form 2024-25",
    date_and_misc_data: {
      start_date: "04/01/2024",
      last_date: " 31/01/2024",
      correction_date: "",
      fees: {
        general: "",
        obc: "",
        sc: "",
        ews: "",
        female: "",
        ph: "",
        all_category: "portal charges only",
      },
      age: { min_age: "21", max_age: "40" },
      exam_date: "18/02/2024",
      apply_link: "https://mpsos.mponline.gov.in/Labour/LabourSchAdmForm",
      official_notification_link:
        "https://mpsos.mponline.gov.in/home/images/Rule_Book_Shramoda.pdf",
      official_web_link: "http://www.labour.mp.gov.in/Default.aspx",
    },
    post_details: [],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "Bihar Legislative Council (Sachivalaya) Recruitment 2024",
    job_details:
      "Bihar Legislative Council Vidhan Parishad Sachivalaya Reporter (Prativedak) Recruitment 2024",
    date_and_misc_data: {
      start_date: "10/01/2024",
      last_date: " 30/01/2024",
      correction_date: "",
      fees: {
        general: "1200/-",
        obc: "1200/-",
        sc: "600/-",
        ews: "1200/-",
        female: "",
        ph: "600",
        all_category: "",
      },
      age: { min_age: "21", max_age: "Male : 37 Year Female : 40" },
      exam_date: "",
      apply_link: "https://blcsrecruitment.com/Advertisement-01.aspx",
      official_notification_link:
        "https://www.biharvidhanparishad.gov.in//Recruitment%202024/01-2024.pdf",
      official_web_link: "https://blcsrecruitment.com/",
    },
    post_details: [
      {
        post_name: "Reporter",
        no_of_post: "11",
        eligibility: [
          "Bachelor Degree in Any Stream in Any Recognized University in India.",
          "Stenographer Speed : 150 WPM in Hindi",
          "Computer Typing : 35 WPM in Hindi and English (Both)",
          "O Level Or Equivalent Degree",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "Bihar Sakshamta Pariksha Examination 2024",
    job_details: "Bihar Sakshamta Pariksha Examination 2024",
    date_and_misc_data: {
      start_date: "01/02/2024",
      last_date: " 15/02/2024",
      correction_date: "",
      fees: {
        general: "",
        obc: "",
        sc: "",
        ews: "",
        female: "",
        ph: "",
        all_category: "1100/-",
      },
      age: { min_age: "NA", max_age: "NA" },
      exam_date: "",
      apply_link: "https://blcsrecruitment.com/Advertisement-01.aspx",
      official_notification_link:
        "https://www.biharvidhanparishad.gov.in//Recruitment%202024/01-2024.pdf",
      official_web_link: "https://blcsrecruitment.com/",
    },
    post_details: [
      {
        post_name:
          "Sakshamta Pariksha 2024 / Local Body Teachers Competency Test ",
        no_of_post: "NA",
        eligibility: [
          "Teachers appointed and working in Primary / Middle / Secondary / Higher Secondary schools of the state by Bihar.",
          "For More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "Bihar Vidhan Sabha Various Post Recruitment 2024",
    job_details: "Bihar Vidhan Sabha Various Post Recruitment 2024",
    date_and_misc_data: {
      start_date: "29/01/2024",
      last_date: " 15/02/2024",
      correction_date: "",
      fees: {
        general: "Attendant Post : 400, Other Post: 600",
        obc: "Attendant Post : 400, Other Post: 600",
        sc: "Attendant Post : 100, Other Post: 150",
        ews: "Attendant Post : 400, Other Post: 600",
        female: "",
        ph: "",
        all_category: "",
      },
      age: { min_age: "18", max_age: "37" },
      exam_date: "",
      apply_link:
        "https://www.sarkariresult.com/bihar/bihar-vidhansabha-01-04-2024/#google_vignette",
      official_notification_link: "https://www.bvscap.in/",
      official_web_link: "https://www.bvscap.in/",
    },
    post_details: [
      {
        post_name: "Assistant Section Officer ASO",
        no_of_post: "50",
        eligibility: [
          "Bachelor Degree in Any Stream in Any Recognized University in India.",
        ],
      },
      {
        post_name: "Assistant Care Taker ACT",
        no_of_post: "04",
        eligibility: [
          "Bachelor Degree in Any Stream in Any Recognized University in India.",
        ],
      },
      {
        post_name: "Junior Clerk",
        no_of_post: "19",
        eligibility: [
          "10+2 Intermediate Exam Passed in Any Recognized Board in India.",
        ],
      },
      {
        post_name: "Reporter",
        no_of_post: "13",
        eligibility: [
          "Bachelor Degree in Any Stream from Any Recognized University in India.",
          "Hindi Steno 150 WPM.",
          "Hindi & English Typing : 35 WPM",
        ],
      },
      {
        post_name: "Personal Assistant",
        no_of_post: "04",
        eligibility: [
          "Bachelor Degree in Any Stream from Any Recognized University in India.",
          "Hindi Steno 100 WPM.",
          "Hindi & English Typing : 30 WPM",
        ],
      },
      {
        post_name: "Stenographer",
        no_of_post: "05",
        eligibility: [
          "Bachelor Degree in Any Stream from Any Recognized University in India.",
          "Hindi Steno 80 WPM.",
          "Hindi & English Typing : 30 WPM",
        ],
      },
      {
        post_name:
          "Library Attendant, Office Attendant (Darbar), Office Attendant Mali, Office Attendant Safaikarmi, Office Attendant Farash",
        no_of_post: "14",
        eligibility: [
          "Class 10th Matric Exam in Any Recognized Board in India.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: true,
    },
    job_heading: "BPSC Recruitment 2024",
    job_details: "BPSC Agriculture Department Recruitment 2024",
    date_and_misc_data: {
      start_date: "15/01/2024",
      last_date: " 28/01/2024",
      correction_date: "",
      fees: {
        general: "750/-",
        obc: "750/-",
        sc: "200/-",
        ews: "750/-",
        female: "",
        ph: "200",
        all_category: "",
      },
      age: { min_age: "21", max_age: "Male : 37 Year Female : 40" },
      exam_date: "",
      apply_link: "https://onlinebpsc.bihar.gov.in/main/home",
      official_notification_link:
        "https://www.bpsc.bih.nic.in/Notices/NB-2024-01-10-05.pdf",
      official_web_link: "https://onlinebpsc.bihar.gov.in/",
    },
    post_details: [
      {
        post_name: "Block Agriculture Officer (BAO)",
        no_of_post: "866",
        eligibility: [
          "Bachelor Degree in Agriculture B.Sc AG from Any Recognized University in India.",
        ],
      },
      {
        post_name:
          "Sub-Divisional Agriculture Officer/Deputy Project Director Atma/Assistant Director Disciple and equivalent",
        no_of_post: "155",
        eligibility: [
          "Bachelor Degree in Agriculture B.Sc AG from Any Recognized University in India.",
        ],
      },
      {
        post_name: "Agriculture Engineering",
        no_of_post: "19",
        eligibility: [
          "BE / B.Tech Degree in Agriculture Engineering from Any Recognized University / Institute in India.",
        ],
      },
      {
        post_name: "Assistant Director Plant Protection",
        no_of_post: "11",
        eligibility: [
          "Bachelor Degree in Agriculture (Elective Plant Protection) from Any Recognized University in India.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: true,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "UP Police Recruitment 2023",
    job_details: "UP Police Computer Operator Recruitment 2023",
    date_and_misc_data: {
      start_date: " 07/01/2024",
      last_date: " 31/01/2024",
      correction_date: "01/02/2024",
      fees: {
        general: "400/-",
        obc: "400/-",
        sc: "400/-",
        ews: "400/-",
        female: "400/-",
        ph: "400/-",
        all_category: "400/-",
      },
      age: { min_age: "21", max_age: "28" },
      exam_date: "",
      apply_link: "https://coga.onlinereg.co.in/",
      official_notification_link:
        "https://uppbpb.gov.in/FilesUploaded/Notice/scan20231229_184700698363ecde-ffbd-4e18-934a-57f6460bec58.pdf",
      official_web_link: "https://uppbpb.gov.in/",
    },
    post_details: [
      {
        post_name: "Computer Operator",
        no_of_post: "930",
        eligibility: [
          "10+2 Intermediate Exam with PCM Subjects and O Level Exam Passed OR Diploma in Computer Application / IT / Electronics Engineering.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "UP Police Recruitment 2023",
    job_details: "UP Police Programmer Recruitment 2023",
    date_and_misc_data: {
      start_date: " 07/01/2024",
      last_date: " 31/01/2024",
      correction_date: "01/02/2024",
      fees: {
        general: "400/-",
        obc: "400/-",
        sc: "400/-",
        ews: "400/-",
        female: "400/-",
        ph: "400/-",
        all_category: "400/-",
      },
      age: { min_age: "21", max_age: "30" },
      exam_date: "",
      apply_link: "https://pg2.onlinereg.co.in/",
      official_notification_link:
        "https://uppbpb.gov.in/FilesUploaded/Notice/scan20231229_185105840cd678bb-aadb-4f54-91e7-5fd37a893e55.pdf",
      official_web_link: "https://uppbpb.gov.in/",
    },
    post_details: [
      {
        post_name: "Programmer Grade II",
        no_of_post: "55",
        eligibility: [
          "Bachelor Degree in Any Stream with NIELIT 'A' Level Exam OR Bachelor Degree in Science B.Sc in Computer Science / IT / Electronics with PGDCA.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "UPPSC Recruitment 2023",
    job_details:
      "UPPSC Combined State or Upper Subordinate Services Examination 2024",
    date_and_misc_data: {
      start_date: " 01/01/2024",
      last_date: " 29/01/2024",
      correction_date: " 09/02/2024",
      fees: {
        general: "125/-",
        obc: "125/-",
        sc: "65/-",
        ews: "125/-",
        female: "",
        ph: "25/-",
        all_category: "",
      },
      age: { min_age: "21", max_age: "40" },
      exam_date: "",
      apply_link: "https://uppsc.up.nic.in/CandidatePages/Notifications.aspx",
      official_notification_link:
        "https://uppsc.up.nic.in/OuterPages/View_Enclosure.aspx?ID=112&flag=E&FID=818",
      official_web_link: "https://uppsc.up.nic.in/",
    },
    post_details: [
      {
        post_name: "Combined Upper Subordinate Services UPPSC Pre 2024",
        no_of_post: "220",
        eligibility: [
          "Bachelor Degree in Any Stream in Any Recognized University in India.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "RPSC Recruitment 2023",
    job_details: "Rajasthan RPSC Vidhi Rachnakar Recruitment 2023-2024",
    date_and_misc_data: {
      start_date: " 05/01/2024",
      last_date: " 04/02/2024",
      correction_date: "",
      fees: {
        general: "600/-",
        obc: "400/-",
        sc: "400/-",
        ews: "",
        female: "",
        ph: "",
        all_category: "",
      },
      age: { min_age: "21", max_age: "40" },
      exam_date: "",
      apply_link: "https://sso.rajasthan.gov.in/signin",
      official_notification_link:
        "https://rpsc.rajasthan.gov.in/Static/RecruitmentAdvertisements/FA5F034E0AC54595A62201E4E9514416.pdf",
      official_web_link: "https://rpsc.rajasthan.gov.in/",
    },
    post_details: [
      {
        post_name: "Vidhi Rachnakar",
        no_of_post: "09",
        eligibility: [
          "Bachelor Degree in Law (LLB) from Any Recognized University in India, English and Hindi as the Subject in BA Exam (At Least One of them being Optional).",
          "Knowledge of Rajasthani Culture.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: true,
    },
    job_heading: "RPSC Recruitment 2024",
    job_details: "RPSC Rajasthan Programmer Recruitment 2024",
    date_and_misc_data: {
      start_date: " 01/02/2024",
      last_date: " 01/03/2024",
      correction_date: "",
      fees: {
        general: "600/-",
        obc: "400/-",
        sc: "400/-",
        ews: "",
        female: "",
        ph: "",
        all_category: "",
      },
      age: { min_age: "21", max_age: "40" },
      exam_date: "",
      apply_link: "https://sso.rajasthan.gov.in/signin",
      official_notification_link:
        "https://rpsc.rajasthan.gov.in/Static/RecruitmentAdvertisements/84EB95EB98BC48CD8488411008E6DD92.pdf",
      official_web_link: "https://rpsc.rajasthan.gov.in/",
    },
    post_details: [
      {
        post_name: "Programmer",
        no_of_post: "216",
        eligibility: [
          "BE / B.Tech / M.SC / M.Tech in IT / OR CS / Electronics and Communication OR MCA  OR MBA IT.",
          "Knowledge of Rajasthani Culture.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: true,
    },
    job_heading: "RPSC Recruitment 2024",
    job_details: "Rajasthan Senior Teacher Recruitment 2024",
    date_and_misc_data: {
      start_date: " 06/02/2024",
      last_date: " 06/03/2024",
      correction_date: "",
      fees: {
        general: "600/-",
        obc: "400/-",
        sc: "400/-",
        ews: "",
        female: "",
        ph: "",
        all_category: "",
      },
      age: { min_age: "21", max_age: "40" },
      exam_date: "",
      apply_link: "https://sso.rajasthan.gov.in/signin",
      official_notification_link:
        "https://rpsc.rajasthan.gov.in/Static/RecruitmentAdvertisements/5429407983BF4A58AF98CBCB289E55CE.pdf",
      official_web_link: "https://rpsc.rajasthan.gov.in/",
    },
    post_details: [
      {
        post_name: "Senior Teacher (Sanskrit Education Department)",
        no_of_post: "347",
        eligibility: [
          "Bachelor Degree in Related Subject/ Degree / Diploma in Education",
          "Final Year Appearing Candidate Also Eligible.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: { 10: false, 12: false, graduate: false },
    job_heading: "RPSC Recruitment 2023",
    job_details: "Rajasthan RPSC Assistant Professor Recruitment 2024",
    date_and_misc_data: {
      start_date: " 22/01/2024",
      last_date: " 21/02/2024",
      correction_date: "",
      fees: {
        general: "600/-",
        obc: "400/-",
        sc: "400/-",
        ews: "",
        female: "",
        ph: "",
        all_category: "",
      },
      age: { min_age: "21", max_age: "40" },
      exam_date: "",
      apply_link: "https://sso.rajasthan.gov.in/signin",
      official_notification_link:
        "https://rpsc.rajasthan.gov.in/Static/RecruitmentAdvertisements/0E3D7DE27E0647EA8D53B1DA960AA71C.pdf",
      official_web_link: "https://rpsc.rajasthan.gov.in/",
    },
    post_details: [
      {
        post_name: "Assistant Professor (Sanskrit College Education)",
        no_of_post: "200",
        eligibility: [
          "Master Degree with 55% Marks in Related Subject  with NET / SLET / SET OR PHd.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: { 10: false, 12: false, graduate: false },
    job_heading: "Rajasthan School Lecturer Recruitment 2024",
    job_details: "Rajasthan School Lecturer Recruitment 2024",
    date_and_misc_data: {
      start_date: " 31/01/2024",
      last_date: " 29/02/2024",
      correction_date: "",
      fees: {
        general: "600/-",
        obc: "400/-",
        sc: "400/-",
        ews: "",
        female: "",
        ph: "",
        all_category: "",
      },
      age: { min_age: "21", max_age: "40" },
      exam_date: "",
      apply_link: "https://sso.rajasthan.gov.in/signin",
      official_notification_link:
        "https://rpsc.rajasthan.gov.in/Static/RecruitmentAdvertisements/C1088FB15A0844CB860ED0D3377EF5A7.pdf",
      official_web_link: "https://rpsc.rajasthan.gov.in/",
    },
    post_details: [
      {
        post_name: "Lecturer School Education (Sanskrit Education Department)",
        no_of_post: "52",
        eligibility: [
          "Master Degree in Related Subject with 48% Marks,",
          "Degree in Education (B.Ed / Shiksha Shastri)",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: true,
    },
    job_heading: "CSIR Central Building Research Institute Recruitment 2024",
    job_details: "CSIR CBRI Technical Assistant Notification 2024 ",
    date_and_misc_data: {
      start_date: " 10/01/2024",
      last_date: " 07/02/2024",
      correction_date: "",
      fees: {
        general: "100/-",
        obc: "100/-",
        sc: "0/-",
        ews: "100/-",
        female: "0/-",
        ph: "0/-",
        all_category: "",
      },
      age: { min_age: "NA", max_age: "28" },
      exam_date: "",
      apply_link: "https://career.csio.res.in/CBRITech82023/",
      official_notification_link:
        "https://cbri.res.in/wp-content/uploads/2024/01/Advt.-8-2023-detailed-Bilangual-Final-09.01.2024-5-pm.pdf",
      official_web_link: "https://cbri.res.in/",
    },
    post_details: [
      {
        post_name: "Technical Assistant (TA)",
        no_of_post: "24",
        eligibility: [
          "Engineering Diploma with 60% Marks  / Bachelor Degree in Science B.Sc with 60% Marks.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: true,
    },
    job_heading: "Northern Coalfield Limited (NCL) Recruitment 2024",
    job_details: "NCLCIL Assistant Foreman Exam 2024",
    date_and_misc_data: {
      start_date: " 15/01/2024",
      last_date: " 05/02/2024",
      correction_date: "",
      fees: {
        general: "1180/-",
        obc: "1180/-",
        sc: "0/-",
        ews: "1180/-",
        female: "",
        ph: "0/-",
        all_category: "",
      },
      age: { min_age: "18", max_age: "30" },
      exam_date: "",
      apply_link:
        "https://cdn.digialm.com/EForms/configuredHtml/1258/86489/Index.html",
      official_notification_link:
        "https://www.nclcil.in/Content/nclcil.in/Document/772Detailed%20Supervisory%20Employment%20Notification%20-%20English.pdf",
      official_web_link: "https://www.nclcil.in/",
    },
    post_details: [
      {
        post_name: "Assistant Foreman (E&T) (Trainee) Grade-C",
        no_of_post: "09",
        eligibility: [
          "Class 10th with Diploma in Engineering (Polytechnic) in Related Trade.",
          "More Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Assistant Foreman (Mechanical) (Trainee) Grade-C",
        no_of_post: "59",
        eligibility: [
          "Class 10th with Diploma in Engineering (Polytechnic) in Related Trade.",
          "More Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Assistant Foreman (Electrical) (Trainee) Grade-C",
        no_of_post: "82",
        eligibility: [
          "Class 10th with Diploma in Engineering (Polytechnic) in Related Trade.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: true,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "JSSC Recruitment 2023",
    job_details: "Jharkhand Police Constable Recruitment 2023",
    date_and_misc_data: {
      start_date: " 22/01/2024",
      last_date: " 21/02/2024",
      correction_date: "26-28 Feb 2024",
      fees: {
        general: "100/-",
        obc: "100/-",
        sc: "50/-",
        ews: "100/-",
        female: "",
        ph: "",
        all_category: "",
      },
      age: { min_age: "18", max_age: "25" },
      exam_date: "",
      apply_link: "https://jsscjcce23.onlinereg.in/",
      official_notification_link:
        "https://jssc.nic.in/sites/default/files/JHARKHAND%20CONSTABLE%20COMPETITIVE%20EXAMINATION%20BROCHURE-2023.pdf",
      official_web_link: "https://jssc.nic.in/",
    },
    post_details: [
      {
        post_name: "Jharkhand Constable",
        no_of_post: "Regular : 3799 Post + Backlog : 1120 Post",
        eligibility: [
          "Class 10th Matric Exam in Any Recognized Board in India.",
          "Height : Male 160 CMS, Female 148",
          "Chest Male Only : 81 CMS",
          "Running :  Male 10 KM in 60 Min, Female : 05 KM in 30 Minutes",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "For Physical Information Please Read Noification",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: true,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "JPSC Recruitment 2024",
    job_details: "JPSC Combined Civil Services Recruitment 2024",
    date_and_misc_data: {
      start_date: " 01/02/2024",
      last_date: " 29/02/2024",
      correction_date: "26-28 Feb 2024",
      fees: {
        general: "100/-",
        obc: "100/-",
        sc: "50/-",
        ews: "100/-",
        female: "",
        ph: "",
        all_category: "",
      },
      age: { min_age: "21", max_age: "35" },
      exam_date: "",
      apply_link: "https://ccsc.digitalexamregistration.com/#/home",
      official_notification_link:
        "https://www.jpsc.gov.in/data/Advertisement_01_24_dated_27_01_2024.pdf",
      official_web_link: "https://www.jpsc.gov.in/",
    },
    post_details: [
      {
        post_name: "Combined Civil Services Exam 2023-24",
        no_of_post: "342",
        eligibility: [
          "Bachelor Degree in Any Stream from Any Recognized University in India.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "For Physical Information Please Read Noification",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: true,
    },
    job_heading: "CHD Constable Executive IT Recruitment 2024",
    job_details: "Chandigarh Constable IT Recruitment 2024",
    date_and_misc_data: {
      start_date: " 23/01/2024",
      last_date: " 13/02/2024",
      correction_date: "",
      fees: {
        general: "1000/-",
        obc: "1000/-",
        sc: "800/-",
        ews: "800/-",
        female: "",
        ph: "",
        all_category: "",
      },
      age: { min_age: "18", max_age: "25" },
      exam_date: "03 March 2024",
      apply_link: "https://cp.chdadmnrectt.in/",
      official_notification_link:
        "https://chandigarhpolice.gov.in/pdf/Recruitment/2024/IT/Recruitment_Notice_Const_IT_2024.pdf",
      official_web_link: "https://chandigarhpolice.gov.in/",
    },
    post_details: [
      {
        post_name: "Constables (Executive IT)",
        no_of_post: "144",
        eligibility: [
          "Bachelor / Master Degree in Computer Science OR Electronics OR Instrumentation OR Communication OR Information Technology OR Mechatronics OR  Computer Applications OR Data Sciences OR Computer Sciences and Allied Fields.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "Education Department, Chandigarh Recruitment 2024",
    job_details:
      "Chandigarh Junior Basic Training JBTs Teacher Recruitment 2024",
    date_and_misc_data: {
      start_date: " 24/01/2024",
      last_date: " 19/02/2024",
      correction_date: "",
      fees: {
        general: "1000/-",
        obc: "1000/-",
        sc: "500/-",
        ews: "1000/-",
        female: "",
        ph: "",
        all_category: "",
      },
      age: { min_age: "21", max_age: "37" },
      exam_date: "",
      apply_link: "https://nltchd.info/utjbt24rect/",
      official_notification_link:
        "https://www.chdeducation.gov.in/wp-content/uploads/2023/12/Recruitment-Notice-for-the-post-of-Junior-Basic-TeacherJBTs.pdf",
      official_web_link: "https://www.chdeducation.gov.in/",
    },
    post_details: [
      {
        post_name: "Junior Basic Training JBT  Primary Teacher",
        no_of_post: "396",
        eligibility: [
          "Bachelor Degree in Any Stream with D.El.Ed and CTET Primary Level Exam Passed.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "Uttarakhand High Court Recruitment 2024",
    job_details:
      "NTA Uttarakhand High Court Junior Assistant & Stenographer  Recruitment 2024",
    date_and_misc_data: {
      start_date: " 25/01/2024",
      last_date: " 22/02/2024",
      correction_date: "",
      fees: {
        general: "1000/-",
        obc: "1000/-",
        sc: "500/-",
        ews: "500/-",
        female: "",
        ph: "",
        all_category: "",
      },
      age: { min_age: "21", max_age: "35" },
      exam_date: "17/03/2024",
      apply_link: "https://uhcrec.ntaonline.in/",
      official_notification_link:
        "https://uhcrec.ntaonline.in/public/uploads/High-Court-Uttarakhand-Information-Bulletin.pdf",
      official_web_link: "https://nta.ac.in/",
    },
    post_details: [
      {
        post_name: "Junior Assistant",
        no_of_post: "57",
        eligibility: [
          "Bachelor Degree in Any Stream from Any Recognized University in India.",
          "Computer Hindi Typing 25 WPM.",
          "More Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Stenographer",
        no_of_post: "82",
        eligibility: [
          "Bachelor Degree in Any Stream from Any Recognized University in India.",
          "Stenographer Hindi Speed : 80 WPM",
          "Computer Hindi Typing 25 WPM.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "NHM UP CHO Recruitment 2024",
    job_details:
      "NHM UP Community Health Officer CHO 5582 Post Recruitment 2024",
    date_and_misc_data: {
      start_date: " 29/01/2024",
      last_date: " 07/02/2024",
      correction_date: "",
      fees: {
        general: "",
        obc: "",
        sc: "",
        ews: "",
        female: "",
        ph: "",
        all_category: "0/-",
      },
      age: { min_age: "21", max_age: "40" },
      exam_date: "",
      apply_link: "https://nhmuprecruitment2023.com/chonhm/",
      official_notification_link:
        "https://upnrhm.gov.in/Uploads/Opportunities/3896594631331800.pdf",
      official_web_link: "https://upnrhm.gov.in/",
    },
    post_details: [
      {
        post_name: "Community Health Officer CHO",
        no_of_post: "5582",
        eligibility: [
          "Bachelor Degree B.Sc. (Nursing) with integrated curriculum of Certificate in Community Health for Nurses (CCHN) and Registration Indian / State Nursing Council.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: true,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "JSSC Recruitment 2023",
    job_details:
      "Jharkhand Paramedical Combined Competitive Examination JPMCCE 2023",
    date_and_misc_data: {
      start_date: " 22/01/2024",
      last_date: " 22/02/2024",
      correction_date: "01-03 March 2024",
      fees: {
        general: "100/-",
        obc: "100/-",
        sc: "50/-",
        ews: "100/-",
        female: "",
        ph: "",
        all_category: "",
      },
      age: { min_age: "18", max_age: "Male : 35, Female: 40" },
      exam_date: "",
      apply_link: "https://jsscparamed.cbtexam.in/JSSC/index.html",
      official_notification_link:
        "https://jsscparamed.cbtexam.in/PDFDocs/Brochure_JPMCCE-2023_Regular_Vacancy.pdf",
      official_web_link: "https://jssc.nic.in/",
    },
    post_details: [
      {
        post_name: "Pharmacist",
        no_of_post: "560",
        eligibility: [
          "Class 10th Matric / 10+2 Intermediate Exam Passed.",
          "Degree / Diploma in Pharmacy with Registration in Council",
          "More Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Laboratory Experimental",
        no_of_post: "636",
        eligibility: [
          "Class 10th Matric / 10+2 Intermediate Exam Passed.",
          "Laboratory Experimental / Lab Technician 1 Year Training.",
          "Registration in Council.",
          "More Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "X-Ray Technician",
        no_of_post: "116",
        eligibility: [
          "10+2 Intermediate (Science) from Any Recognized Board in India.",
          "Diploma in Radiography / X Ray / CT Scan / USG / MRI  Technician with Registration in Council.",
          "More Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Nurse Grade A",
        no_of_post: "1173",
        eligibility: [
          "10+2 Intermediate Exam (PCB) Stream from Any Recognized Board in India.",
          "3 Year 6 Month GNM Certificate with Registration in Council.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "For Physical Information Please Read Noification",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: true,
      12: true,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "National Defence Academy Recruitment 2023",
    job_details: "NDA Pune Various Post Recruitment 2024",
    date_and_misc_data: {
      start_date: " 27/01/2024",
      last_date: " 16/02/2024",
      correction_date: "",
      fees: {
        general: "",
        obc: "",
        sc: "",
        ews: "",
        female: "",
        ph: "",
        all_category: "0/-",
      },
      age: { min_age: "18", max_age: "27" },
      exam_date: "",
      apply_link: "https://ndacivrect.gov.in/index.html#/",
      official_notification_link: "https://ndacivrect.gov.in/pdf/RECTADVT.PDF",
      official_web_link: "https://ndacivrect.gov.in/",
    },
    post_details: [
      {
        post_name: "Multi Tasking StaffOffice & Training (MTS-O&T)",
        no_of_post: "151",
        eligibility: [
          "Class 10th Matric / 10+2 Intermediate Exam Passed.",
          "More Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Lower Division Clerk LDC",
        no_of_post: "16",
        eligibility: [
          "10+2 Intermediate Exam from Any Recognized Board in India.",
          "Typing English 35 WPM OR Hindi 30 WPM.",
          "More Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Stenographer Steno Grade II",
        no_of_post: "01",
        eligibility: [
          "10+2 Intermediate Exam from Any Recognized Board in India.",
          "Dictation : 80 WPM",
          "Transcription : 50 Min English OR 65 Min Hindi",
          "More Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Draughtsman",
        no_of_post: "02",
        eligibility: [
          "10+2 Intermediate Exam from Any Recognized Board in India.",
          "Diploma in Draughtsman Ship 2 Years or ITI Certificate as Draughtsman with 2 Year Experience.",
          "More Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Cinema Projectionist II",
        no_of_post: "01",
        eligibility: [
          "10+2 Intermediate Exam from Any Recognized Board in India with 2 Year Experience.",
          "More Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Cook",
        no_of_post: "14",
        eligibility: [
          "10+2 Intermediate Exam from Any Recognized Board in India with 2 Year Experience. OR ITI Certificate as Cook with 2 Year Experience.",
          "More Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Compositor Cum Printer",
        no_of_post: "01",
        eligibility: [
          "10+2 Intermediate Exam from Any Recognized Board in India with 2 Year Experience.",
          "More Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Civilian Motor Driver OG",
        no_of_post: "03",
        eligibility: [
          "10+2 Intermediate Exam from Any Recognized Board in India with 2 Year Experience.",
          "Civilian Driving License for Heavy Vehicles.",
          "More Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Carpenter",
        no_of_post: "02",
        eligibility: [
          "10+2 Intermediate Exam from Any Recognized Board in India / ITI Certificate as Carpenter with 2 Year Experience.",
          "More Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "Fireman",
        no_of_post: "02",
        eligibility: [
          "Class 10th Matric with Heavy Vehicle Driving License, 6 Month Certificate as per Post.",
          "More Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "TA-Baker & Confectioner",
        no_of_post: "01",
        eligibility: [
          "ITI Certificate in Baker and Confectioner OR Class 10th and 1 Year Experience.",
          "More Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "TA-Cycle Repairer",
        no_of_post: "02",
        eligibility: [
          "ITI Certificate in Cycle Repairer OR Class 10th and 1 Year Experience.",
          "More Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "TA-Printing Machine Optr",
        no_of_post: "01",
        eligibility: [
          "ITI Certificate in Printing Machine Operator OR Class 10th and 1 Year Experience.",
          "More Eligibility Details Read the Notification.",
        ],
      },
      {
        post_name: "TA-Boot Repairer",
        no_of_post: "01",
        eligibility: [
          "ITI Certificate in Boot Repairer OR Class 10th and 1 Year Experience.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "For Physical Information Please Read Noification",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "NIACL Assistant Recruitment 2024",
    job_details: "NIACL Assistant Recruitment 2024",
    date_and_misc_data: {
      start_date: " 01/02/2024",
      last_date: " 15/02/2024",
      correction_date: "",
      fees: {
        general: "850/-",
        obc: "850/-",
        sc: "100/-",
        ews: "850/-",
        female: "",
        ph: "100/-",
        all_category: "",
      },
      age: { min_age: "21", max_age: "30" },
      exam_date: "02/03/2024",
      apply_link: "https://www.newindia.co.in/recruitment/list",
      official_notification_link:
        "https://www.newindia.co.in/assets/docs/recruitment/RECRUITMENT-OF-ADMINISTRATIVE-OFFICERS2023/DETAILED%20ENGLISH%20ADVERTISEMENT%20-%20ASSISTANT%20RECRUITMENT%202023.pdf",
      official_web_link: "https://www.newindia.co.in/",
    },
    post_details: [
      {
        post_name: "Assistant",
        no_of_post: "300",
        eligibility: [
          "Bachelor Degree in Any Stream in Any Recognized University in India.",
          "English as a Subject in Matric / Inter / Degree Level.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: false,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "SCI Law Clerk Recruitment 2024",
    job_details: "SCI Law Clerk Cum Research Associates Recruitment 2024",
    date_and_misc_data: {
      start_date: " 25/01/2024",
      last_date: " 15/02/2024",
      correction_date: "",
      fees: {
        general: "",
        obc: "",
        sc: "",
        ews: "",
        female: "",
        ph: "",
        all_category: "500/-",
      },
      age: { min_age: "20", max_age: "32" },
      exam_date: "",
      apply_link: "https://jobapply.in/supremeCourtLawClerk2024/",
      official_notification_link:
        "https://jobapply.in/supremeCourtLawClerk2024/adv_eng.pdf",
      official_web_link: "https://www.sci.gov.in/",
    },
    post_details: [
      {
        post_name: "Law Clerk Cum Research Associates",
        no_of_post: "90",
        eligibility: [
          "Bachelor Degree in Law (LLB) from Any Recognized University in India.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
  {
    id: Date.now(),
    category: "govtjobs",
    minimun_eligibility: {
      10: true,
      12: false,
      graduate: true,
      egdiploma: false,
    },
    job_heading: "Railway SR Apprentices 2860 Post 2024",
    job_details: "Railway SR Apprentices 2860 Post Recruitment 2024",
    date_and_misc_data: {
      start_date: " 29/01/2024",
      last_date: " 28/02/2024",
      correction_date: "",
      fees: {
        general: "100/-",
        obc: "100/-",
        sc: "0/-",
        ews: "0/-",
        female: "",
        ph: "0/-",
        all_category: "",
      },
      age: { min_age: "15", max_age: "24" },
      exam_date: "",
      apply_link: "https://iroams.com/RRCSRApprentice24/recruitmentIndex",
      official_notification_link:
        "https://sr.indianrailways.gov.in/cris/uploads/files/1706504069979-ActApprentice_2023-24.pdf",
      official_web_link: "https://sr.indianrailways.gov.in/",
    },
    post_details: [
      {
        post_name: "RRC SR Southern Region Various Trade Apprentices 2024",
        no_of_post: "2860",
        eligibility: [
          "Freshers : Class 10th Exam with Minimum 50% Marks.",
          "Ex ITI : Class 10 High School / Matric with ITI Certificate in Related Trade.",
          "More Eligibility Details Read the Notification.",
        ],
      },
    ],
    physical_data: "",
  },
];

export const sortedMasterData = masterData.slice().sort((a, b) => {
  // Accessing the start_date property within date_and_misc_data
  const dateA = new Date(
    a.date_and_misc_data.start_date.split("/").reverse().join("/")
  );
  const dateB = new Date(
    b.date_and_misc_data.start_date.split("/").reverse().join("/")
  );

  return dateB - dateA;
});

import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { sortedMasterData } from "./MasterData";
import GJob from "./asset/Govt. Jobs.png";
import universityIcon from "./asset/university.png";
import privateJob from "./asset/private.jpg";
import news from "./asset/news.jpg";
import mp from "./asset/mp.png";
import admission from "./asset/admission.png";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

function QualificationFilter() {
  const [filteredContent, setFilteredContent] = useState([]);
  const { classholder } = useParams();
  useEffect(() => {
    const filteredData = sortedMasterData?.filter(
      (data) => data?.minimun_eligibility[`${classholder}`] === true
    );
    if (filteredData.length) {
      setFilteredContent(filteredData);
    } else {
      setFilteredContent([]);
    }
  }, [classholder]);
  return (
    <Box display="flex" justifyContent="start" flexDirection="column" pb={10}>
      <Button variant="contained" size="large" color="success">
        Latest Jobs
      </Button>
      {filteredContent?.map((data) => (
        <Link
          to={`/jobs/${data.job_details.replace(/\s+/g, "-").toLowerCase()}`}
          style={{ textDecoration: "none" }}
        >
          <Box sx={{ width: "100%", bgcolor: "background.paper" }} id={data.id}>
            <Typography aria-label="main body">
              <List>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon sx={{ marginRight: "8px" }}>
                      <img
                        src={
                          data.category === "govtjobs"
                            ? GJob
                            : data.category === "privatejobs"
                            ? privateJob
                            : data.category === "news"
                            ? news
                            : data.category === "mpgovtjobs"
                            ? mp
                            : data.category === "admission"
                            ? admission
                            : universityIcon
                        }
                        alt={data.category}
                        height={50}
                        width={100}
                      />
                    </ListItemIcon>
                    <ListItemText primary={data.job_details} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Typography>
            <Divider />
          </Box>
        </Link>
      ))}
    </Box>
  );
}

export default QualificationFilter;

import { Box, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <Box
        sx={{
          height: "80px",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 20px",
          position: "fixed",
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          flexWrap:"wrap",
          background:"#fff"
        }}
      >
        <Box pl={5} pr={5}>
          <Typography sx={{textWrap:"nowrap"}}>© 2024 News Khabree All rights reserved!</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" pr={5} pl={5}>
          <NavLink to="/about_us" style={{ textDecoration: "none" }}>
            <Typography
              sx={{
    
                padding: "8px",
              }}
            >
              About us
            </Typography>
          </NavLink>
          <NavLink to="/disclamer" style={{ textDecoration: "none" }}>
            <Typography
              sx={{
    
                padding: "8px",
              }}
            >
              Disclamer
            </Typography>
          </NavLink>
          <NavLink to="/privacy_policy" style={{ textDecoration: "none" }}>
            <Typography
              sx={{
    
                padding: "8px",
              }}
            >
              Privacy Policy
            </Typography>
          </NavLink>
          <NavLink to="/contact_us" style={{ textDecoration: "none" }}>
            <Typography
              sx={{
    
                padding: "8px",
              }}
            >
              Contact us
            </Typography>
          </NavLink>
        </Box>
      </Box>
    </>
  );
};

export default Footer;

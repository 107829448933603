import { Box, Typography } from "@mui/material";
import React from "react";

function PrivacyPolicy() {
  return (
    <Box pl={5} pr={5} pb={10} mt={5} sx={{fontSize: "25px", fontStyle: "italic", }}>
    <Typography align="justify" variant="h3" color="secondary" pb={2}>Privacy Policy</Typography>
      <Box>
        <Typography align="justify" variant="h4" color="primary">Introduction</Typography>
        <Typography align="justify">
          Welcome to newskhabree.com, The general news information platform.
          This Privacy Policy described the ways in which we collect, use,
          disclose, and protect information gathered from visitors to our
          website newskhabree.com. By using our Site, you consent to the terms
          outlined in this Privacy Policy.
        </Typography>
      </Box>
      <Box>
        <Typography align="justify" variant="h4" color="primary">Information We Collect</Typography>
        <Typography align="justify">
          We may collect personal information, including but not limited to
          names, email addresses, and postal addresses, when voluntarily
          provided by users through contact forms on our Site. We automatically
          receive and record information from your browser when you visit our
          Site. This Log Data may include your IP address, browser type, the
          pages you visit, and the time spent on those pages.
        </Typography>
      </Box>
      <Box>
        <Typography align="justify" variant="h4" color="primary">Use of Information</Typography>
        <Typography align="justify" sx={{marginLeft: "20px"}}>
          We use the information we collect in various ways, including to:
          <ul>
            <li>Provide, operate, and maintain our website</li>
            <li>Improve, personalize, and expand our website</li>
            <li>Understand and analyze how you use our website</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>
              Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the website, and for
              marketing and promotional purposes
            </li>
            <li>Send you emails</li>
            <li>Find and prevent fraud</li>
          </ul>
          <Typography align="justify" sx={{marginLeft: "20px"}}>
            <strong>Log Files </strong>
            <br />
            <Typography align="justify" sx={{marginLeft: "20px"}}>
              <strong>newskhabree.com</strong> follows a standard procedure of
              using log files. These files log visitors when they visit
              websites. All hosting companies do this and a part of hosting
              services analytics. The information collected by log files include
              internet protocol (IP) addresses, browser type, Internet Service
              Provider (ISP), date and time stamp, referring/exit pages, and
              possibly the number of clicks. These are not linked to any
              information that is personally identifiable. The purpose of the
              information is for analyzing trends, administering the site,
              tracking users movement on the website, and gathering demographic
              information.
            </Typography>
          </Typography>
          <Typography align="justify" sx={{marginLeft: "20px"}}>
            <strong>Cookies and Web Beacons</strong>
            <br />
            <Typography align="justify" sx={{marginLeft: "20px"}}>
              Like any other website, <strong>newskhabree.com</strong> uses
              cookies. These cookies are used to store information including
              visitors preferences, and the pages on the website that the
              visitor accessed or visited. The information is used to optimize
              the users experience by customizing our web page content based on
              visitors browser type and/or other information.
            </Typography>
          </Typography>
          <Typography align="justify" sx={{marginLeft: "20px"}}>
            <strong>DoubleClick DART Cookie</strong>
            <br />
            <Typography align="justify" sx={{marginLeft: "20px"}}>
              Google is one of a third-party vendor on our site. It also uses
              cookies, known as DART cookies, to serve ads to our site visitors
              based upon their visit to www.undefined and other sites on the
              internet. However, visitors may choose to decline the use of DART
              cookies by visiting the Google ad and content network Privacy
              Policy at the following URL{" "}
              <a href="https://policies.google.com/technologies/ads">
                https://policies.google.com/technologies/ads
              </a>
              .
            </Typography>
          </Typography>
        </Typography>
      </Box>
      <Box>
        <Typography align="justify" variant="h4" color="primary">Advertising Partners Privacy Policies</Typography>
        <Typography align="justify">
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of
          <strong>newskhabree.com</strong>. Third-party ad servers or ad
          networks uses technologies like cookies, JavaScript, or Web Beacons
          that are used in their respective advertisements and links that appear
          on <strong>newskhabree.com</strong>, which are sent directly to users
          browser. They automatically receive your IP address when this occurs.
          These technologies are used to measure the effectiveness of their
          advertising campaigns and/or to personalize the advertising content
          that you see on websites that you visit.
          <Typography align="justify">
            Note that <strong>newskhabree.com</strong>4 has no access to or
            control over these cookies that are used by third-party advertisers.
          </Typography>
        </Typography>
      </Box>
      <Box>
        <Typography align="justify" variant="h4" color="primary">Third-Party Privacy Policies</Typography>
        <Typography align="justify">
          <strong>newskhabree.com</strong> Privacy Policy does not apply to
          other advertisers or websites. Thus, we are advising you to consult
          the respective Privacy Policies of these third-party ad servers for
          more detailed information. It may include their practices and
          instructions about how to opt-out of certain options. You may find a
          complete list of these Privacy Policies and their links here: Privacy
          Policy Links. You can choose to disable cookies through your
          inBoxidual browser options. To know more detailed information about
          cookie management with specific web browsers, it can be found at the
          browsers respective websites. What Are Cookies?
        </Typography>
      </Box>
      <Box>
        <Typography align="justify" variant="h4" color="primary">CCPA Privacy Policy (Do Not Sell My Personal Information)</Typography>
        <Typography align="justify">
          Under the CCPA, among other rights, California consumers have the
          right to: Request that a business that collects a consumer’s personal
          data disclose the categories and specific pieces of personal data that
          a business has collected about consumers. Request that a business
          delete any personal data about the consumer that a business has
          collected. Request that a business that sells a consumer’s personal
          data, not sell the consumer’s personal data. If you make a request, we
          have one month to respond to you. If you would like to exercise any of
          these rights, please contact us.
        </Typography>
      </Box>
      <Box>
        <Typography align="justify" variant="h4" color="primary">GDPR Privacy Policy (Data Protection Rights)</Typography>
        <Typography align="justify">
          We would like to make sure you are fully aware of all of your data
          protection rights. Every user is entitled to the following: The right
          to access – You have the right to request copies of your personal
          data. We may charge you a small fee for this service. The right to
          rectification – You have the right to request that we correct any
          information you believe is inaccurate. You also have the right to
          request that we complete the information you believe is incomplete.
          The right to erasure – You have the right to request that we erase
          your personal data, under certain conditions. The right to restrict
          processing – You have the right to request that we restrict the
          processing of your personal data, under certain conditions. The right
          to object to processing – You have the right to object to our
          processing of your personal data, under certain conditions. The right
          to data portability – You have the right to request that we transfer
          the data that we have collected to another organization, or directly
          to you, under certain conditions. If you make a request, we have one
          month to respond to you. If you would like to exercise any of these
          rights, please contact us.
        </Typography>
      </Box>
      <Box>
        <Typography align="justify" variant="h4" color="primary">Children’s Information</Typography>
        <Typography align="justify">
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
          <strong>newskhabree.com</strong> does not knowingly collect any
          Personal Identifiable Information from children under the age of 13.
          If you think that your child provided this kind of information on our
          website, we strongly encourage you to contact us immediately and we
          will do our best efforts to promptly remove such information from our
          records.
        </Typography>
      </Box>
      <Box>
        <Typography align="justify" variant="h4" color="primary">Contact Information</Typography>
        <Typography align="justify">
          If you have question or query, feel free to contact us or you can
          directly mail to us at{" "}
          <strong sx={{color:"blue"}}>query@newskhabree.com</strong>
        </Typography>
      </Box>
    </Box>
  );
}

export default PrivacyPolicy;

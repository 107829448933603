import { Box, Typography } from "@mui/material";
import React from "react";

function Disclamer() {
  return (
    <Box pl={5} pr={5} mt={5} pb={10} sx={{fontSize: "25px", fontStyle: "italic", }}>
      <Typography align="justify" variant="h3" color="secondary" pb={2}>Disclaimer</Typography>
      <Typography align="justify" variant="h6">
        Welcome to <strong style={{color:"yellow"}}>newskhabree.com</strong>, a news blog where user can
        find news about
        <strong style={{color:"yellow"}}> Education, Entertainment, Technology</strong> related
        information which are popular and famous of news industries.
      </Typography>

      <Typography align="justify" variant="h6">
        The all related news from various article publication, innovation,
        market research and officially released by the concern authority will be
        published in trust and for general information purpose only. While we
        strive to ensure the accuracy and reliability of the information
        presented, we make no representations or warranties of any kind, express
        or implied, about the completeness, accuracy, reliability contained on
        the website for any purpose. Any reliance you place on such information
        is strictly at your own risk.
      </Typography>

      <Typography align="justify" variant="h6">
        We provide only genuine and faith links which will officially provided
        in ethical manner. Our website may contain links to external websites
        that are not provided or maintained by us. We do not guarantee the
        accuracy, relevance, timeliness, or completeness of any information on
        these external websites. We are not provider of any fake or unethical
        links in our website.
      </Typography>

      <Typography align="justify" variant="h6">
        If user jump to another webpage from our website for any other
        information. We are not responsible for these information and content
        what user found from there and we are also not give assurance about
        availability of that information which user is looking.
      </Typography>

      <Typography align="justify" variant="h6">
        <strong style={{color:"yellow"}}>newskhabree.com</strong> allows users to comment on articles and
        submit user-generated content. We do not endorse or control the content
        of user comments and submissions, and we reserve the right to remove any
        content that violates our policies.
      </Typography>

      <Typography align="justify" variant="h6">
        <strong style={{color:"yellow"}}>newskhabree.com</strong> may update this disclaimer from time to
        time. We encourage you to review this page periodically for any changes.
      </Typography>

      <Typography align="justify" variant="h6">
        Please be sure to check the Privacy Policies of these sites as well as
        their “Terms of Service” before engaging in any business or uploading
        any information.
      </Typography>

      <Typography align="justify" variant="h6">
        If you have any questions or concerns about this disclaimer, please
        contact us at{" "}
        <a href="https://newskhabree.com/contact-us/">Contact us</a>
      </Typography>

      <Typography align="justify" variant="h6">
        Thank you for visiting <strong style={{color:"yellow"}}>newskhabree.com</strong> and being a part
        of our community.
      </Typography>
    </Box>
  );
}

export default Disclamer;

import { Box, Typography } from "@mui/material";
import React from "react";

function PrivateJobs() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
    >
      <Typography variant="h2">Will update soon</Typography>
    </Box>
  );
}

export default PrivateJobs;
